import React from 'react';
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarBtnWrap, SidebarRoute, SidebarLink } from './SidebarElements';

const Sidebar = ({ isOpen, toggle }) => {
  return (
   <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
         <CloseIcon/>
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to='about' onClick={toggle}>
           Despre noi
          </SidebarLink> 
          <SidebarLink to="services" onClick={toggle}>
           Servicii
          </SidebarLink>
          <SidebarLink to="mandoc" onClick={toggle}>
           Management documente
          </SidebarLink>
        </SidebarMenu>  
        <SidebarBtnWrap>
           <SidebarRoute to = {{ pathname: "/docman" }} >Conectare</SidebarRoute>
        </SidebarBtnWrap>
      </SidebarWrapper> 
   </SidebarContainer>
  );
};

export default Sidebar;