import styled from 'styled-components';
import {Link as LinkR} from 'react-router-dom';
import {Link as LinkS} from 'react-scroll';
import Logo from '../../images/logo.png';

export const Nav = styled.nav`
  background: ${({scrollNav}) => (scrollNav ? '#368574' : 'transparent')};
  height: 85px;
  margin-top: -85px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10; 
  width: 100%;
  @media screen and (max-width: 960px) {
   transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
 display: flex;
 justify-content: space-between;
 height: 80px;
 z-index: 10;
 width: 100%;
 padding: 0 24px;
 max-width: 1200px;
`;

export const NavLogo = styled.img.attrs({src: Logo})`
    width: 200px;
    height: 80px;
    margin: 2px;
`;

export const MobileIcon = styled.div`
 display: none;
  
 @media screen  and (max-width: 768px) {
   display: block;
   position: absolute;
   top: 0;
   right: 0;
   transform: translate(-100%, 60%)
   font-size: 1.8rem;   
   cursor: pointer;
   color: #fff;
  }
`;

export const NavMenu = styled.ul`
  color: ${({darkText}) => (darkText ? '#010606' : '#737373')};
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
 height: 80px;
`;

export const NavLinks = styled(LinkS)` 
 color: #434343;
 display: flex;
 align-items: center;
 text-decoration: none;
 padding: 0 1rem;
 height: 100%;
 cursor: pointer;
 &.active { 
   border-bottom: 2px solid #fff;
   color: #fff;
 }
`;

export const NavBtn = styled.nav`
 display: flex;
 align-items: center;

 @media screen and (max-width: 768px) { 
   display: none;
 }
`;

export const NavBtnLink = styled(LinkR)`
 border-radius: 20px;
 background: #368574;
 white-space: nowrap;
 padding: 10px 22px;
 color: #fff;
 font-size: 16px;
 outline: none;
 border: none;
 cursor: pointer;
 transition: all 0.2s ease-in-out;
 text-decoration: none;

 &:hover{
   transition: all 0.2 ease-in-out;
   background: #fff;
   color: #368574;
 }
`;
