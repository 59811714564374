import styled from "styled-components";

export const ServicesContainer = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 1100px;
  background: ${({lightBG}) => (lightBG ? '#f9f9f9' : '#f9f9f9')};

`;

export const ServicesWrapper = styled.div`
 max-width: 1358px;
 margin: 0 auto;
 display: grid;
 grid-template-columns: 1fr 1fr;
 align-items: center;
 grid-gap: 20px;
 padding: 0 10px;

 @media screen and (max-width: 1200px) {
     grid-template-columns: 1fr ;
 }
`;

export const ServicesCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 30px;
  width: 550px;
  height: 450px;
  max-height: 700px;
  max-width: 700px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: 0.2 all ease-in-out;

  &hover { 
      transform: scale(1.02);
      transition: all 0.2 ease-in-out;
      cursor: pointer;
  }
`;

export const ServicesIcon = styled.img`
 height: 200px;
 width: 250px;

`;

export const ServicesH2 = styled.h2`
 color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#368574')};
 font-size: 1rem;
 margin-bottom: 10px;
 padding-left: 20px;
 padding-right: 20px;
 padding-bottom: 15px;
`;

export const ServicesP = styled.p`
color: ${({lightText}) => (lightText ? '#606060' : '#929292')};
 font-size: 14px;
 font-weight: 400;
 line-height: 15px;
 text-align: left;
`;

export const TooltipText = styled.div`
  background: #fff;
  color: #fff;
  padding-top: 25px;
  width: 500px;
  font-size: 10px;
  text-align: center;
  line-height: 10px;
  border-radius: 3px;
  cursor: pointer;
`;

export const TooltipBox = styled.div`
  position: absolute;
    z-index: 10;
  top: calc(100%);
  left: 20px;
  font-size: 13px;
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  width: 700px;
  padding: 5px 5px;
  border-radius: 4px;

  transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s,
    padding 0.5s ease-in-out;

  &:before {
    content: "";
    width: 0px;
    height: 0px;
    left: 20px;
    top: -9px;
    position: absolute;

    border: 10px solid transparent;
    transform: rotate(135deg);
    transition: border 0.3s ease-in-out;
  }
`;
export const TooltipCard = styled.div`
  position: relative;
  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    color: #368574;
    background-color: #f7f7f7;
    width: 680px;
    padding: 8px 8px;

    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8)
        rgba(0, 0, 0, 0.8);
    }
  }
`;