import React from 'react';
import Icon1 from '../../images/Calculator24.png';
import Icon2 from '../../images/Bani24.png';
import Icon3 from '../../images/Expertiza.png';
import Icon4 from '../../images/Personal241.png';
import Icon5 from '../../images/Resurse24.png';
import Icon6 from '../../images/Expertiza24.png';
import Icon7 from '../../images/mng24.png';
import Icon8 from '../../images/mandoc.png';
import {
    ServicesCard,
    ServicesContainer,
    ServicesH2,
    ServicesIcon,
    ServicesP,
    ServicesWrapper,
    TooltipBox,
    TooltipCard,
    TooltipText

} from './ServicesElements';


function Services() {
    return (
            <ServicesContainer id="services">
                <ServicesWrapper>
                    <ServicesCard>
                        <TooltipCard>
                            <TooltipText>
                                <ServicesIcon src={Icon1}/>
                                <ServicesH2>Contabilitate primara</ServicesH2>
                                <ServicesP>Contabilitatea primară poate fi realizata de un angajat al firmei dvs sau prin delegarea unui coleg al nostru, în funcţie de necesităţi.</ServicesP>
                            </TooltipText>
                            <TooltipBox>
                                <p>1.Contabilitatea registrului de casă;</p>
                                <p>2.Contabilitatea notelor de intrare-recepţie (NIR-uri);</p>
                                <p>3.Întocmirea deconturilor de cheltuieli;</p>
                                <p>4.Întocmirea de dispoziţii de plată/încasare către casierie;</p>
                                <p>5.Completarea de ordine de plată, foi de vărsământ;</p>
                            </TooltipBox>
                        </TooltipCard>
                    </ServicesCard>

                    <ServicesCard>
                        <TooltipCard>
                            <TooltipText>
                                <ServicesIcon src={Icon2}/>
                                <ServicesH2>Contabilitate Financiara</ServicesH2>
                                <ServicesP>În cadrul unei societaţi comerciale este esenţială existenţa unei contabilitaţi performante, rolul acesteia fiind de a înregistra operaţiile economice, de a întocmi documentele contabile de sinteză şi de a interpreta rezultatele activitaţii economice a societaţii.</ServicesP>
                            </TooltipText>
                            <TooltipBox>
                                <p>1. Înregistrarea cronologică a articolelor contabile pe baza documentelor
                                    primare;</p>
                                <p>2. Realizăm situaţii financiare intermediare la cerere şi în funcţie de
                                    necesităţi;</p>
                                <p>3. Evidenţă analitică şi sintetică clienţi, furnizori;</p>
                                <p>4. Evidenţă mijloace fixe, calculul amortizării.</p>
                                <p>5. Elaborarea balanţei de verificare lunară;</p>
                                <p>6. Întocmirea registrului jurnal, registrului inventar, jurnalelor de TVA şi cartea
                                    mare;</p>
                                <p>7. Contabilitate de gestiune;</p>
                                <p>8. Întocmire şi depunere declaraţii lunare, trimestriale;</p>
                                <p>9. Calculul impozitului pe profit sau a impozitului pe veniturile
                                    microintreprinderii,
                                    dupa caz, întocmirea şi depunerea declaraţiilor în termenele legale;</p>
                                <p>10. Calculul impozitului pe dividende, întocmirea şi depunerea declaraţiilor privind
                                    impozitul pe dividende;</p>
                                <p>11. Pregătirea documentelor pentru inventarierea anuală;12. Întocmire declaraţii
                                    rectificative, inclusiv a balanţei din luna precedentă, pentru documentele
                                    financiar-contabile primite după termenul de predare al declaraţiilor (15, respectiv
                                    25
                                    a lunii următoare)</p>
                            </TooltipBox>
                        </TooltipCard>
                    </ServicesCard>

                    <ServicesCard>
                        <TooltipCard>
                            <TooltipText>
                                <ServicesIcon src={Icon4}/>
                                <ServicesH2>Personal / Salarizare</ServicesH2>
                                <ServicesP>Într-un mediu fiscal descris de incertitudine legislativă şi în care confidenţialitatea este o condiţie de bază, serviciile de salarizare sunt vitale pentru o afacere.</ServicesP>
                            </TooltipText>
                            <TooltipBox>
                                <p>1. Înregistrarea firmei la ITM;</p>
                                <p>2. Întocmire state de salarii, verificare pontaje;</p>
                                <p>3. Întocmire decizii incetare, decizii majorare salarii, acte adiţionale;</p>
                                <p>4. Întocmirea notelor de lichidare pentru personalul beneficiarului;</p>
                                <p>5. Consultanţă privind salarizarea;</p>
                                <p>6. Întocmire declaraţii rectificative, inclusiv a statelor de plată corectate privind
                                    planurile de măsuri stabilite de ITM</p>
                                <p>7. Informarea permanentă a conducerii şi personalului decizional în privinţa
                                    situaţiei financiare a firmei, a impozitelor ce urmează a fi plătite sau a
                                    eventualelor probleme care pot exista în gestiunea societaţii. </p>
                            </TooltipBox>
                        </TooltipCard>
                    </ServicesCard>


                    <ServicesCard>
                        <TooltipCard>
                            <TooltipText>
                                <ServicesIcon src={Icon3}/>
                                <ServicesH2>Auditarea facturilor</ServicesH2>
                                <ServicesP>Auditul facturilor este o activitate independenta si obiectiva care ofera unei compani asigurarea in ceea ce priveste gradul de control asupra operatiunilor de facturare</ServicesP>
                            </TooltipText>
                            <TooltipBox>
                                <p>1. Verificarea respectarii normelor legale in ceea ce priveste
                                    circuitul documentelor si vizele necesare pentru angajarea societatii in plati si incasari de
                                    la furnizori si clienti</p>
                                <p>2. Verificarea inregistrarii veniturilor
                                    consemnate in facturi de clienti si furnizori comparandu-le cu baza de impozitare</p>
                                <p>3. Verificarea prin sondaj a facturilor emise si a facturilor primite pentru programe din fonduri comunitare</p>
                            </TooltipBox>
                        </TooltipCard>
                    </ServicesCard>

                    <ServicesCard>
                        <TooltipCard>
                            <TooltipText>
                                <ServicesIcon src={Icon5}/>
                                <ServicesH2>Resurse umane</ServicesH2>
                                <ServicesP>Construieşte o companie mai bună cu ajutorul unor angajaţi mai buni. Una dintre cele mai bune metode de a-ţi dezvolta afacerea este aceea de a-ţi perfecţiona personalul – adică, de a le mări valoarea şi fidelitatea ghidându-i în propriile cariere profesionale.</ServicesP>
                            </TooltipText>
                            <TooltipBox>
                                <p>1. Întocmire contract individual de muncă actualizat</p>
                                <p>2. Întocmire act adiţional la CIM pentru conformare cu Legea nr.40/2011</p>
                                <p>3. Întocmire decizii de modificare salarii, încetare contracte de muncă, suspendare
                                    contracte de muncă</p>
                                <p>4. Inregistrarea on-line la ITM in </p>

                                <p>- contractul individual de muncă</p>
                                <p>- actele adiţionale pentru modificarea contractelor de muncă</p>
                                <p> - deciziile de modificare a salariilor, deciziile de încetare a contractelor de
                                    muncă, decizile de suspendare a contractelor de muncă</p>
                                <p>5. Actualizarea Registrului general de evidenţă a salariaţilor conform Ordinului
                                    nr.1918/25.07.2011(referitor la salarii, sporuri şi cuantumul acestora)
                                </p>
                                <p>6. Diverse documente necesare în materie de resurse umane (adeverinţe de salariat
                                    pentru medicul de familie, pentru spital, pentru obţinerea de credite, etc)</p>
                                <p>7. Întocmire adeverinţe de salariat pentru obţinerea indemnizaţiei de şomaj</p>
                                <p>8. Fişa postului</p>
                                <p>9. Fişa de evaluare a performanţelor salariale</p>
                                <p>10. Evaluare personal în vederea întocmirii fişei</p>
                                <p>11. Regulamentul intern</p>
                                <p>12. Regulamentul intern actualizat</p>
                                <p>13. Verificare pontaje lunare</p>
                            </TooltipBox>
                        </TooltipCard>
                    </ServicesCard>

                    <ServicesCard>
                        <TooltipCard>
                            <TooltipText>
                                <ServicesIcon src={Icon6}/>
                                <ServicesH2>Expertiza si Revizii Contabile</ServicesH2>
                                <ServicesP>Expertiza contabilă este o formă de cercetare ştiinţifică în vederea lămuririi modului în care sunt reflectate în documente şi în evidenţa tehnic-operativă şi contabilă anumite fapte, împrejurări, situaţii, etc. de natură financiar-contabilă. Expertiza contabilă este misiunea data unui expert de a controla registrele, conturile şi actele justificative ale unui agent economic în scopul de a furniza părţilor interesate datele pentru o echitabilă regularizare a situaţiei.</ServicesP>
                            </TooltipText>
                            <TooltipBox>
                                <p>1.Expertiza contabilă judiciară în Sibiu</p>
                                <p>2.Expertiza contabilă amiabilă în Sibiu</p>
                                <p>3.Evaluarea patrimoniului societaţii</p>
                                <p>4.Activitate de cenzorat în Sibiu</p>
                                <p>5.Consolidarea balanţelor de verificare pentru grupuri de societăţi şi transpunerea lor în bilanţ</p>
                                <p>6.Raportare semestrială</p>
                                <p>7.Situaţie financiară anuală simplificată</p>
                                <p>8.Verificarea şi certificarea bilanţului contabil pentru firme din Sibiu</p>
                                <p>Proact srl aplica conform legii urmatoarele standarde profesionale: </p>
                                <li>Standardul profesional nr. 21 Misiune de tinere a contabilitatii, intocmirea si prezentarea situatiilor financiare</li>
                                <li>Standardul profesional nr. 22 Misiunea de examinare a contabilităţii, întocmirea, semnarea şi prezentarea situaţiilor finanicare</li>
                                <li>Standardul profesional nr. 35 Expertizele contabile</li>
                                <li>Standardul profesional nr. 36 Misiunile de audit intern realizate de experti contabili</li>
                                <li>Standardul profesional nr. 37 Misiunea de evaluare a întreprinderii</li>
                                <li>Standardul profesional nr. 39 Misiunea de consultanţă pentru crearea întreprinderilor</li>
                            </TooltipBox>
                        </TooltipCard>
                    </ServicesCard>


                    <ServicesCard>
                        <TooltipCard>
                            <TooltipText>
                                <ServicesIcon src={Icon7}/>
                                <ServicesH2>Managementul Firmelor</ServicesH2>
                                <ServicesP>Previziune, organizare, coordonare, antrenare, control si evaluare</ServicesP>
                                <ServicesP>Managementul firmelor reprezinta un ansamblu de studii privind ralatiile de management din cadrul acestora, in vederea descoperirii principiilor care le genereaza si a conceperii de noi sisteme, tehnici, metode si modalitati de conducere avind rezultat asigurarea si obtinerea cresterii competitivitatii, materializata in profit. In practica moderna investitorul- patronul asigura bunul mers al firmei prin contract de management, incheiat cu entitati fizice sau juridice, prin care se stabilesc indicatorii de realizat.</ServicesP>
                            </TooltipText>
                            <TooltipBox>
                                <p>Echipa PROACT srl se implica in managementul strategic:</p>
                                <li>Elaboreaza impreuna cu echipa manageriala un plan de dezvoltare a companiei, pe termen scurt, mediu si lung si il propune spre aprobare administratorului si actionarilor</li>
                                <li>Propune spre apobare planul anual privind directiile de dezvoltare strategica</li>
                                <li>Propune modificari privind structura organizatorica</li>
                                <li>Analizeaza modul de indeplinire a obligatiilor personalului si al colaboratorilor</li>
                                <p>Managementul economico financiar:</p>
                                <li>Asista si avizeaza in vederea apobarii bugetului de venituri si cheltuieli al societatii- BVC</li>
                                <li>Verifica si avizeaza repartizarea bugetului de venituri si cheltuieli pe sectii si structuri ale societatii</li>
                                <li>Urmareste executia BVC-ului pe sectii sau structuri organizatorice</li>
                                <li>Verifica modul de organizare si desfasurare a activitatii de control/audit intern</li>
                                <p>Managementul resurselor umane:</p>
                                <li>Elaboreaza impreuna cu echipa manageriala organigrama companiei</li>
                                <li>Participa la stabilirea bugetului cu forta de munca</li>
                                <li>Intocmeste dosarele de personal si inregistraza evidenta in REVISAL</li>
                                <li>Participa la intocmirea criteriilor de performanta si urmareste realizarea acestora, participind la evaluarea personalului</li>
                                <li>Elaboreaza analiza organizationala a companiei</li>
                                <p>Managementul administrativ:</p>
                                <li>Participa la incheierea de acte juridice in numele clientului in baza unui mandat acordat</li>
                                <li>Verifica modul de indeplinire a obligatiilor asumate prin contracte de catre societate si propune masuri de imbunatatire</li>
                                <li>Urmareste modul de implementare a investitiilor si a reparatiilor</li>

                            </TooltipBox>
                        </TooltipCard>
                    </ServicesCard>


                    <ServicesCard>
                        <TooltipCard>
                            <TooltipText>
                                <ServicesIcon src={Icon8}/>
                                <ServicesH2>Management Documente</ServicesH2>
                                <ServicesP>Sistem securizat pentru urmarire si control al documentelor</ServicesP>
                            </TooltipText>
                            <TooltipBox>
                                <p>Clasificarea documentelor dupa tip si categorie</p>
                                <p>Trasabilitate pe versiunile multiple ale documentului</p>
                                <p>Cautare intuitiva a documentelor in arhiva</p>
                                <p>Controlul accesului in arhiva in functie de rolul utilizatorului</p>
                            </TooltipBox>
                        </TooltipCard>
                    </ServicesCard>
                </ServicesWrapper>
            </ServicesContainer>
    )
}

export default Services