import styled from "styled-components";
import Background from "../../images/bg.jpg";
import EU from "../../images/EU.PNG";

export const HeroContainer = styled.div`
 background-color: #101522;
 display: flex;
 justify-content: center;
 align-items: center;
 padding: 0 0px;
 height: 800px;
 width: 800px

 position: relative;
 z-index: 1;

 :before { 
     content: '';
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100% ) , linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%),
     z-index: 2;
 }
`;

export const HeroBg = styled.div`
 position: absolute;
 top: 0;
 right: 0;
 bottom: 0;
 left: 0;
 width: 100%;
 height: 100%;
 overflow: hidden;
`;

export const ImgBg = styled.img.attrs({src:Background})`
    width: 100%;
    height: 100%;
    margin: 0px;
    -o-object-fit: cover;
    object-fit: cover;
`;

export const ImgEU = styled.img.attrs({src:EU})`
    width: 80%;
    margin: 0px;
    -o-object-fit: cover;
    object-fit: cover;
`;

export const HeroContent = styled.div`
 z-index: 3;
 max-width: 1200px;
 position: absolute;
 padding: 1px 24px;
 display: flex;
 flex-direction: column;
 align-items: center;
`;

export const HeroH1 = styled.h1`
 color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#368574')};
 font-size: 48px;
 text-align: center;
 margin-top: 1px;

 @media screen and (max-width: 768px) {
     font-size: 40px;
 }

 @media screen and (max-width: 480px) { 
     font-size: 32px;
 }
`;

export const HeroP = styled.p`
 margin-top: 30px;
 color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#368574')};
 font-size: 24px;
 text-align: center;
 max-width: 700px;

 @media screen and (max-width: 768px) {
     font-size: 24px;
 }

@media screen and (max-width: 480px) {
    font-size: 18px;
}
`;
