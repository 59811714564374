import React from 'react'
import { FooterContainer, FooterLinkItems, FooterLinkTitle,
    FooterLinksContainer, FooterLinksWrapper, FooterWrap,
    DetailsText} from './FooterElements';

const Footer = () => {

  return (
    <FooterContainer>
        <FooterWrap>
            <FooterLinksContainer>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle></FooterLinkTitle>
                        <DetailsText></DetailsText>

                        <DetailsText>Conţinutul acestui material nu reprezintă în mod obligatoriu poziţia oficială a Uniunii Europene sau a Guvernului României</DetailsText>
                    </FooterLinkItems>
                    <FooterLinkItems>
                       <FooterLinkTitle>PROACT srl</FooterLinkTitle>
                        <DetailsText>Adresa: Str. G. Toparceanu, nr. 53</DetailsText>
                        <DetailsText>Sibiu, jud. Sibiu, Romania</DetailsText>
                    </FooterLinkItems>
                    <FooterLinkItems>
                        <FooterLinkTitle></FooterLinkTitle>
                        <DetailsText></DetailsText>
                        <DetailsText>Telefon / Fax: +40 269 235320</DetailsText>
                        <DetailsText to='mailTo'>Email: proactsibiu@gmail.com</DetailsText>
                    </FooterLinkItems>
                    <FooterLinkItems>
                        <FooterLinkTitle></FooterLinkTitle>
                        <DetailsText>Pentru informații detaliate despre celelate programe cofinanțate de Uniunea Europeană, vă invităm să vizitați
                            <a href="https://www.fonduri-ue.ro" target="_blank" rel="noreferrer"> www.fonduri-ue.ro </a>
                        </DetailsText>
                    </FooterLinkItems>
                </FooterLinksWrapper>
            </FooterLinksContainer>
        </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
