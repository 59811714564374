import React from 'react';
import {HeroContainer, HeroBg, ImgBg, ImgEU, HeroContent, HeroH1, HeroP} from './HeroElements';

const HeroSection = () => {

    return (
        <HeroContainer id='home'>
            <HeroBg>
                <ImgBg/>,
            </HeroBg>
            <HeroContent>
                <HeroH1>
                    <a href="https://www.fonduri-ue.ro" target="_blank" rel="noreferrer">
                        <ImgEU/>
                    </a>
                </HeroH1>
                <HeroH1>Activităţi de contabilitate şi audit financiar</HeroH1>
                <HeroH1>Consultanţă în domeniul fiscal</HeroH1>
                <HeroH1>Management de documente</HeroH1>
                <HeroP>
                    Contactează-ne pentru a analiza impreună necesităţile firmei tale
                </HeroP>

            </HeroContent>
        </HeroContainer>
    );
};

export default HeroSection;
