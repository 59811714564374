import styled from "styled-components";

export const InfoContainer = styled.div`
 color: #ffffff;
 background: ${({lightBG}) => (lightBG ? '#f9f9f9' : '#f9f9f9')};
display: flex;
align-items:center;
 @media screen and (max-width: 768px) {
   padding: 100px 0;
 }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 18px;
  justify-content: center;
`;

export const InfoCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 30px;
  padding: 40px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: 0.2 all ease-in-out;

  &hover { 
      transform: scale(1.02);
      transition: all 0.2 ease-in-out;
      cursor: pointer;
  }
`;

export const InfoRow = styled.div`
 align-items: center;
 margin_left: 20px;
grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};
`;

export const TextWrapper = styled.div`
 max-width: 1200px;
 padding-top: 12px;
 padding-bottom: 5px;
`;

export const TopLine = styled.p`
  color: #368574;
  font-size: 24px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-top: 1px;
  
`;

export const Heading = styled.h5`
  color: #000;
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({lightText}) => (lightText ? '#606060' : '#929292')};

  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
`;

export const Subtitle = styled.p`
 max-width: 1200px;
 margin-bottom: 5px;
 font-size: 12px;
 line-height: 18px;
 align-items: left;
 color: ${({darkText}) => (darkText ? '#929292' : '#c5c4c4')};
`;

export const ImgWrap = styled.div`
 align: center;
 max-width: 75px;
 height: 100%;
`;

export const Img = styled.img`
 width: 100%;
 margin: 0 0 10px 0;
 padding-right: 0;
`;
