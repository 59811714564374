import React from 'react';
import HeroSection from '../components/LandingPage';
import InfoSection from '../components/DespreNoi';
import Navbar from '../components/Navbar';
import Services from '../components/Servicii';
import Sidebar from '../components/Sidebar';
import { homeObjOne, homeObjThree, homeObjTwo } from '../components/DespreNoi/Data';
import Footer from '../components/Footer';
import { useState } from 'react';

const Home = () => {
   const [isOpen, setIsOpen] = useState(false)

   const toggle = () => {
    setIsOpen(!isOpen)
   };
  
  
  
   return (
    <>
       <Sidebar isOpen={isOpen} toggle={toggle}/>
       <Navbar toggle={toggle}/>
       <HeroSection />
        <div>
        </div>
       <InfoSection {...homeObjOne} />
        <div>
        </div>
       <InfoSection {...homeObjTwo} />
        <div>
        </div>
       <Services />
        <div>
        </div>
       <InfoSection {...homeObjThree} />
       <Footer />

    </>
  );
};

export default Home;
