export const homeObjOne = {
    id: 'about',
    lightBg: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Despre noi',
    headline: 'În economia actuală, modernă, externalizarea serviciilor contabile, expertizelor contabile, recrutarea si selectia personalului si a managementului a devenit o necesitate pentru toţi agenţii economici.',
    description1: 'De la microintreprinderi până la marii contribuabili, toate societaţile trebuie să respecte aceleaşi reguli şi aceeaşi legislaţie încâlcită, şi să lupte cu birocraţia pentru a putea funcţiona. În acest sens, firma noastră oferă servicii de evidenţă contabilă, audit, evidenta resurse umane si management firmelor din orice domeniu de activitate.\n',
    description2: 'Ne bazam pe intelegerea reciproca a companiilor si vrem sa crestem impreuna cu clientii nostri luandu-ne angajamentul de a ii ajuta pe acestia sa obtina un avantaj competitiv prin eficienta si promptitudine.\n',
    description3: 'Înfiinţată în anul 1994, membră a Corpul Experţilor Contabili şi Contabililor Autorizaţi din România(CECCAR), societatea PROACT reuneşte o echipă de specialişti în diverse domenii, care vin în întâmpinarea oamenilor de afaceri şi a entitaţilor de toate mărimile, oferind un ajutor considerabil întreprinzatorilor din toate sferele de activitate.\n',
    description4: 'Având experienţă de peste 20 de ani în domeniu, specialiştii noştri oferă o gamă complexă de soluţii profesioniste în contabilitate, audit, resurse umane si management, relevante pentru toate problemele curente ale oricărei firme sau persoane implicate într-un mediu de afaceri.\n',
    description5: 'Principalul nostru obiectiv este de a oferi servicii personalizate în funcţie de nevoile clientului, preluînd toate sarcinile ce nu ţin strict de activitatea managerului, câştigînd astfel timp preţios pe care dvs. îl puteţi dedica afacerilor.\n',
    description6: 'Ne propunem să oferim cele mai bune soluţii în domeniul financiar-contabil, politicii manageriale şi resurselor umane, astfel încât să asigurăm partenerilor noştri un background solid în realizarea obiectivelor.\n',
    description7: 'Oferim servicii organizatiilor mici si mijlocii, cu capital integral privat, roman si/sau strain, indiferent de domeniul lor de activitate. Serviciile oferite de noi sunt indreptate exclusiv catre imbunatatirea si perfectionarea activitatii clientului. Astfel, clientul dispune in mod curent de toate informatiile financiar-contabile si manageriale, acestea fiind suportul deciziilor economice si financiare necesare in coordonarea activitatii unei societati.\n',
    description8: 'Pentru firme mijlocii si mari asiguram servicii lunare de verificarea documentelor intocmite, calculul impozitelor, intocmirea raportarilor si o consultanta permanenta in domeniul financiar – contabil . Aceste servicii ne antreneaza complet cunostintele si raspunderea in asigurarea unor informatii corecte si complete , foarte utile conducerii in luarea deciziilor.\n',
    description9: 'Oferta noastra se adreseaza clientilor firme mijlocii si mari care au un compartiment financiar-contabil si o activitate de volum si compexitate ridicata, la care conducerea are nevoie de un ochi ager din afara care sa certifice informatiile obtinute si sa dea interpretarea corecta rezultatelor. Aceasta activitate se desfasoara la client si antreneaza direct conducerea firmei noastre.\n',
    description10: 'Oferim management in domeniul startegiei firmelor, economico- financiar, a resurselor umane si in domeniul administativ, asigurind desfasurarea eficienta a activitatilor, urmarind obtinerea nivelului maxim de rezultate prin folosirea optima a resurselor aflate la dispozitie.\n',
    description11: 'PROACT are asigurare de raspundere civila profesionala, malpraxis. Aceasta asigurare are ca scop despagubirea prejudiciilor cauzate in principal din neglijenta in exercitarea profesiei noastre, care oferim consultanta sau prestam un serviciu specializat. Asigurarea pentru malpraxis acopera erori, omiteri sau neglijente comise de angajatii nostrii, care aduc pagube clientilor.',
    imgStart: false,
    img: require('../../images/MG_0015-2.jpg'),
    img1: require('../../images/autorizatie.jpg'),
    img2: require('../../images/aviz2016.jpg'),
    img3: require('../../images/diploma.jpg'),
    img4: require('../../images/certificat.jpg'),
    img5: require('../../images/CECCAR.png'),
    alt: 'Head',
    dark: false,
    primary: true,
    darkText: true
};

export const homeObjTwo = {
    id: 'services',
    lightBg: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Servicii',
    imgStart: true,
    img: require('../../images/MG_00321.jpg'),
    headline: 'Avantajele externalizării contabilităţii, a serviciilor de personal, a serviciilor de expertiza contabila si a serviciilor de managementul firmelor:',
    description1: 'Reducerea costurilor cu activitatea contabilă\n',
    description2: 'Economisirea timpului dedicat contabilitaţii\n',
    description3: 'Evitarea costurilor suplimentare pentru refacerea contabilitaţii\n',
    description4:    'Asigurarea unei imagini profesionale\n',
    description5: 'Accesul la competenţa profesională a experţilor contabili\n',
    description6: 'Reducerea riscului dvs şi transferarea acestuia către firma de contabilitate\n',
    description7: 'Recrutarea si selectia personalului se face intr-un timp rezonabil si fara riscuri\n',
    description8: 'Expertizele contabile sint realizate de personal cu experienta in domeniu\n',
    description9: 'Managementul se organizeaza in in conditii de realizare a unor parametrii performanti\n',
    description10: 'Evitarea cheltuielilor pentru crearea unui loc de munca pentru contabil, expert contabil, expert resurse umane (spaţiu, mobilier, tehnică de calcul, programe de contabilitate şi informatii si acces la legislaţie)',
    alt: 'Staff',
    dark: false,
    primary: true,
    darkText: true
};

export const homeObjThree = {
    id: 'mandoc',
    lightBg: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Management documente',
    headline: 'Pentru clientii nostri oferim gratuit dreptul de folosinta a sistemului pentru management de documente',
    description1: 'Arhivarea documentelor dupa tip, categorie si autor',
    description2: 'Evidenta versiunilor documentelor',
    description3: 'Adaugarea si editarea de comentarii si notificari la document',
    description4: 'Securizarea accesului la documente',
    buttonLabel: 'Start now',
    imgStart: false,
    img: require('../../images/Capture.png'),
    alt: 'paper',
    dark: false,
    primary: true,
    darkText: true
};