import React, { useCallback } from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import './App.css';
import particlesOptions from "./particles.json";

function Docman() {
    window.location.href = 'https://proactexpert.ro/docman';
    return null;
}

function App() {
    const particlesInit = useCallback(main => {
        loadFull(main);
    }, [])

    return (
        <div className="App">
            <Particles options={particlesOptions} init={particlesInit}/>
            <header className="App-header">
                <Router>
                    <Routes>
                        <Route exact path='/' element={< Home/>}/>
                        <Route
                            exact path="/docman"
                            element={<Docman/>}
                        />
                    </Routes>

                </Router>

            </header>
        </div>
    );
}
export default App;
