import React from 'react';
import {Grid, Col, Row} from 'react-styled-flexboxgrid';
import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    ImgWrap,
    Img,
    InfoCard
} from './InfoElements';

const About = ({
                   lightBg,
                   id,
                   imgStart,
                   topLine,
                   lightText,
                   headline,
                   darkText,
                   description1,
                   description2,
                   description3,
                   description4,
                   description5,
                   description6,
                   description7,
                   description8,
                   description9,
                   description10,
                   description11,
                   img,
                   img1,
                   img2,
                   img3,
                   img4,
                   img5
               }) => {
    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                    <InfoCard>
                        <InfoRow imgStart={imgStart}>
                            <TextWrapper>
                                <TopLine>{topLine}</TopLine>
                                <Grid>
                                    <Row around="md">
                                        <Col xs={6} md={7}>
                                            <Img src={img}/>
                                        </Col>
                                        <Col xs={6} md={2}>
                                            <ImgWrap>
                                                <Img src={img5}/>
                                            </ImgWrap>
                                        </Col>
                                    </Row>
                                </Grid>
                                <Heading darkText={lightText}>{headline}</Heading>
                                <Subtitle darkText={darkText}>{description1}</Subtitle>
                                <Subtitle darkText={darkText}>{description2}</Subtitle>
                                <Subtitle darkText={darkText}>{description3}</Subtitle>
                                <Subtitle darkText={darkText}>{description4}</Subtitle>
                                <Subtitle darkText={darkText}>{description5}</Subtitle>
                                <Subtitle darkText={darkText}>{description6}</Subtitle>
                                <Subtitle darkText={darkText}>{description7}</Subtitle>
                                <Subtitle darkText={darkText}>{description8}</Subtitle>
                                <Subtitle darkText={darkText}>{description9}</Subtitle>
                                <Subtitle darkText={darkText}>{description10}</Subtitle>
                                <Subtitle darkText={darkText}>{description11}</Subtitle>
                                <Grid>
                                    <Row around="xs">
                                        <Col xs={6} md={2}>
                                            <Img src={img1}/>
                                        </Col>
                                        <Col xs={6} md={3}>
                                            <Img src={img2}/>
                                        </Col>
                                        <Col xs={6} md={3}>
                                            <Img src={img3}/>
                                        </Col>
                                        <Col xs={6} md={3}>
                                            <Img src={img4}/>
                                        </Col>
                                    </Row>
                                </Grid>
                            </TextWrapper>
                        </InfoRow>
                    </InfoCard>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default About